<template>
  <div class="payment-checkout-success-page">
    <div class="readable_content form_content">
      <h4><check-icon size="64" />Thanks, your payment was successful!</h4>
      <p class="payment-details">
        <strong>We've sent a payment confirmation to your email</strong>. Our staff will be in contact soon with details
        for your purchase.
      </p>
      <hr />
      <p>
        If you have any addditional questions please feel to contact us at
        <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>.
      </p>
      <!--<p>In the meantime, why not:</p>
      <ul>
        <li>Search for records in our <router-link :to="{name: 'search-tool-zupu'}">Chinese Genealogical Records database</router-link>.</li>
        <li><a href="http://mychinaroots.com/blog/">Check out our blog</a> for inspiring stories and genealogy tips.</li>
        <li>Learn more about <router-link :to="{name: 'our-story'}">My China Roots and our origins</router-link>.</li>
      </ul>-->
    </div>
  </div>
</template>

<script>
import CheckIcon from 'vue-material-design-icons/Check';

export default {
  name: 'PaymentCheckoutSuccessPage',
  components: {CheckIcon},
};
</script>

<style scoped lang="scss">
h4 {
  color: $success-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  .material-design-icon {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    .material-design-icon__svg {
      margin: 0;
      border: 1px solid rgba($success-color, 0.5);
      border-radius: 50%;
      padding: 8px;
    }
  }
}

.payment-details {
  padding: 8px 0;
}
</style>
